import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDictionary, MeasurementUnit, RecipeCategory } from '@gfs/shared-models';
import { LocalizedValueUtilsService, WINDOW } from '@gfs/shared-services';
import { GetMeasurementUnitsAttempt } from '@gfs/store/feature/add-items';
import { AppState, selectAllCategories } from '@gfs/store/recipe/reducers';
import { OnInitEffects } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'gfs-app-print-recipe-summary',
  templateUrl: './print-recipe-summary.component.html',
  styleUrls: ['./print-recipe-summary.component.scss']
})
export class PrintRecipeSummaryComponent implements OnInit ,OnDestroy{
  summaryData = this.data.recipes.filter((recipe)=> !recipe.deleted && !recipe.details.batch);
  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(selectAllCategories));
  currentCustomer$ = this.store.select(state => state.auth.customerName)
  categoryList : RecipeCategory[] = []
  recipeWithCategoryList
  categoryNames: {};
  destroy$ = new Subject();
  public removeRecipePrintEventListener: () => void;
  @ViewChild('pdfTable') pdfTable :ElementRef;
  standardMeasurementUnits$: Observable<MeasurementUnit[]> = this.store
    .select((state) => state.addItemsFeature.measurementUnits);
  resolvedMeasurementUnits : MeasurementUnit[]
  constructor( 
  public dialogRef: MatDialogRef<PrintRecipeSummaryComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  @Inject(WINDOW) private window: Window,
  public elementRef: ElementRef, 
  public renderer: Renderer2,
  private store: Store<AppState>,

  ){
    combineLatest([
      this.categories$,
      this.standardMeasurementUnits$
    ]).pipe(takeUntil(this.destroy$)).subscribe((data)=>{
      this.categoryList = data[0];
      this.resolvedMeasurementUnits = data[1];
      this.recipeWithCategoryList =  this.mapRecipeTocategory()
    })
    
  }
  public downloadAsPDF() {
    const doc = new jsPDF();
    const options = {
      html2canvas: {
        scale: 0.2
      },
      x: 0,
      y: 0,
      width: 210,
      windowWidth: 1000,
      
    }
    const pdfTable = this.pdfTable.nativeElement;
    doc.html(pdfTable,options).then(() => doc.save('RecipeBook.pdf'));
  }
  ngOnInit(): void {
    this.removeRecipePrintEventListener = this.renderer.listen(document, 'keydown', (event) => {
      if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey && (!event.shiftKey)) {event.preventDefault();
        this.downloadAsPDF();}
    });
    }
    ngOnDestroy(): void {
    this.removeRecipePrintEventListener();
    }

  mapRecipeTocategory(){
  const categorizedItems = {};
  const categoryNames = {}
  categorizedItems['unassigned'] = []
  this.categoryList.forEach(category => {
  categorizedItems[category.id] = [];
  categoryNames[category.id]=[category.name]
  });
  this.categoryNames = categoryNames
    this.summaryData.forEach(item => {
  if (categorizedItems[item.categoryId]) {
    categorizedItems[item.categoryId].push(item);
  } else {
    categorizedItems['unassigned'].push(item)
  }
});

const recipeSummaryObject = Object.entries(categorizedItems).map(([key, value]) => ({
  categoryid: key,
  items: value,
  categoryAverages: this.calculateCategoryAverage(value)
}));
return recipeSummaryObject
}

calculateCategoryAverage(items): any {
  if (items.length === 0) {
      return {}
  }
  const totalItems = items.length
  let sellingPrice = 0;
  let grossProfit = 0;
  let foodCostPercentage = 0;
  let totalPortion =0;
  
  items.forEach((item)=>{
      totalPortion = totalPortion + item.recipePrice?.totalCost
      sellingPrice = sellingPrice + item.recipePrice.menuPrice
      grossProfit = grossProfit + (item.recipePrice.menuPrice - item.recipePrice.totalCost)
      foodCostPercentage = foodCostPercentage + item.recipePrice.foodCostPercent
  })
  return {
    portionCostAvg : totalPortion ? totalPortion/totalItems : 0,
    sellingPriceAvg: sellingPrice ? sellingPrice/totalItems : 0, 
    grossProfitAvg : grossProfit? grossProfit/totalItems : 0,
    foodCostPercentageAvg : foodCostPercentage ? foodCostPercentage/totalItems : 0
}

}

}
