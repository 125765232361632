import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Recipe, RecipeCategory } from '@gfs/shared-models';
import { AppConfigService } from '@gfs/shared-services';
import { AppState, selectAllCategories, selectAllRecipes } from '@gfs/store/recipe/reducers';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, concatMap, first, firstValueFrom } from 'rxjs';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { DownloadRecipeSummaryReport } from '@gfs/store/recipe/actions/recipe.actions';
import { InventoryConstants } from '@gfs/constants';
import { PrintRecipeSummaryComponent } from '../print-recipe-summary/print-recipe-summary.component';

@Component({
  selector: 'gfs-recipe-reporting',
  templateUrl: './recipe-reporting.component.html',
  styleUrl: './recipe-reporting.component.scss'
})
export class RecipeReportingComponent implements OnInit{
  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  currentLang$ = this.store.select(state => state.layout.language);
  customerPk$ = this.store.select(state => state.auth.pk);
  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(selectAllCategories));
  recipes$: Observable<Recipe[]> = this.store.pipe(select(selectAllRecipes));
  customerName$ = this.store.select(state => state.auth.customerName);
  excelData;
  constructor( public translate: TranslateService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    ){}

  ngOnInit(): void {
    combineLatest([
      this.currentLang$,
      this.customerPk$
    ]).subscribe((data)=> {
      this.excelData = data
    });
  }

downloadExcell(customerName): void {
  this.openRecipeSummaryLoadingModal();
    this.store.dispatch(new DownloadRecipeSummaryReport({ locale: this.excelData[0], customerPk:this.excelData[1] ,customerName:customerName }));
}

 async openRecipePdf() {
  return this.recipes$
      .pipe(
        first(),
        concatMap(recipes => {
          return firstValueFrom(
            this.dialog.open(PrintRecipeSummaryComponent, {
              data: { recipes },
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '100%',
              width: '100%',
            }).afterClosed()
          );
        }),
      ).toPromise();
  }
  openRecipeSummaryLoadingModal() {
    this.dialog.open(LoadingModalComponent, {
      id: InventoryConstants.LOADING_MODAL_ID,
      panelClass: 'recipe-mat-dialog',
      disableClose: true
    });
  }
}
