
<div class="CTA-Container">
    <div class="print-container" >
      <button class="primary-button" (click)="downloadAsPDF()">{{ 'RECIPE_SUMMARY_REPORTING.DOWNLOAD_PDF' | translate }}</button>
    </div>
  <div class="no-print close-button">
      <button class="close primary-button" (click)="dialogRef.close()">
        {{ 'RECIPE_SUMMARY_REPORTING.BACK_TO_REPORTS' | translate }}
      </button>
    </div>
  </div>

<div class="dialog-content" #pdfTable>
    <div class="display printable-container"  id="pdfTable">
      <div class="summary-table">
            
                <th class="summary-header">
                    <h3 class="margin-l"> {{ 'RECIPE_SUMMARY_REPORTING.RECIPE_SUMMARY_REPORT' | translate }} </h3>
                    <p class="margin-l">{{ 'RECIPE_SUMMARY_REPORTING.FOR_COOKBOOK' | translate }} {{ currentCustomer$ | async }}</p>
                    <tr class="table-row-header_Recipes">
                        <td class="no-border"></td>
                        <td class="no-border"></td>
                        <td class="row-description"> {{ 'RECIPE_SUMMARY_REPORTING.DESCRIPTION' | translate }} </td>
                        <td >  {{ 'RECIPE_SUMMARY_REPORTING.PORTION_PRICE' | translate }} </td>
                        <td >  {{ 'RECIPE_SUMMARY_REPORTING.PORTION_DESCRIPTION' | translate }} </td>
                        <td >  {{ 'RECIPE_SUMMARY_REPORTING.SELLING_PRICE' | translate }} </td>
                        <td >  {{ 'RECIPE_SUMMARY_REPORTING.GROSS_PROFIT' | translate }} </td>
                        <td style="border-right: 2px solid black;"> {{ 'RECIPE_SUMMARY_REPORTING.FOOD_COST_%' | translate }} </td>
                    </tr>
                </th>
            
            
            </div>
        <div>
            <div  *ngFor="let recipe of recipeWithCategoryList">
              <div *ngIf="!(recipe.categoryid === 'unassigned' && recipe.items.length === 0)" >
                <tr class="category-row" >
                  <td >
                    {{ 'RECIPE_SUMMARY_REPORTING.CATEGORY' | translate }} 
                  </td>
                  <td class="category-names"><h3>{{ (categoryNames[recipe.categoryid] === undefined ? 'Unassigned' : categoryNames[recipe.categoryid]) }}</h3></td>
                </tr>
               <tr class="category-value" *ngFor="let recipedata of recipe.items">
                  <td Class="empty-row"></td>
                  <td class="recipe-name"> {{ recipedata.name }} </td>
                  <td class="portion-price table-data"> {{ recipedata.recipePrice?.totalCost?.toFixed(2) }} </td>
                  <td class="portion-desc"> {{ '(1.0) ' + 'Each' }} </td>
                  <td class="table-data"> {{ recipedata.recipePrice?.menuPrice.toFixed(2) }}</td>
                  <td class="table-data"> {{ (recipedata.recipePrice?.menuPrice - recipedata.recipePrice?.totalCost).toFixed(2) }} </td>
                  <td class="table-data"> {{ recipedata.recipePrice?.foodCostPercent.toFixed(2) + '%' }} </td>
              </tr>
              <div class="line-break"></div>
              <tr>
                  <td class="category-average"> {{ 'RECIPE_SUMMARY_REPORTING.CATEGORY_AVERAGES' | translate }} </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="portion-cost-avg average">{{ recipe.categoryAverages?.portionCostAvg?.toFixed(2) }}</td>
                  <td></td>
                  <td class="average">{{ recipe.categoryAverages?.sellingPriceAvg?.toFixed(2) }}</td>
                  <td class="average">{{ recipe.categoryAverages?.grossProfitAvg?.toFixed(2) }}</td>
                  <td class="average">{{ recipe.categoryAverages?.foodCostPercentageAvg?.toFixed(2) }}</td>
              </tr>

              </div>
            </div>  
        </div>
    </div>
    
</div>