<div class="recipe-reports-container">
    <h3 class="header">
        {{ 'RECIPE_SUMMARY_REPORTING.RECIPE_VALUE_REPORT' | translate }}
    </h3>

<div class="recipe-reporting-summary">
    <div class="Info-text">
        <h3 class="summary-header">
            <p class="text-start summary-heading">{{ 'RECIPE_SUMMARY_REPORTING.RECIPE_SUMMARY' | translate }} </p>
        </h3>
        <p class="text-start summary-welcome-msg">
            {{ 'RECIPE_SUMMARY_REPORTING.RECIPE_REPORTING_WELCOME' | translate }}
        </p>
            <p class="text-start summary-sub-heading">
                {{ 'RECIPE_SUMMARY_REPORTING.DETAILS_INFO_TEXT' | translate }}
            </p>
            <ul class="bullet-list">
                <li>{{ 'RECIPE_SUMMARY_REPORTING.LIST_INFO_FIRST' | translate }}</li>
                <li>{{ 'RECIPE_SUMMARY_REPORTING.LIST_INFO_SECOND' | translate }}</li>
              </ul>

           <p class="text-start">{{ 'RECIPE_SUMMARY_REPORTING.ELEVATE_MESSAGE' | translate }}</p> 
           <div class="buttons-container" *ngIf="(customerName$ | async) as customerName">
            <button 
            class="primary-button large-button report-cta"
            type="button"
            (click)="openRecipePdf()"
            *ngIf="false"
            >
            {{ 'RECIPE_SUMMARY_REPORTING.VIEW_SUMMARY_BTN' | translate }}
            </button>
                <button 
                class="primary-button large-button report-cta"
                type="button"
                (click)="downloadExcell(customerName)"
                >
                {{ 'RECIPE_SUMMARY_REPORTING.DOWNLOAD_EXCEL_BTN' | translate }}
                </button>
        </div>
    </div>
    <div class="img-container">
        <img
        src="assets/images/MicroStrategyReportPreview.jpg"
        alt="Image"
      >
    </div>
</div>

</div>