import { HttpErrorResponse } from '@angular/common/http';
import { CustomerPK, Recipe, RecipeIngredient, RecipePrice, Worksheet } from '@gfs/shared-models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetRecipesAttempt = '[Recipe] Get Recipes Attempt',
  SetActiveRecipeId = '[Recipe] Set Active Recipe Id',
  RecipeLoading = '[Recipe] Recipe Loading',
  GetRecipesSuccess = '[Recipe] Get Recipes Success',
  GetRecipesError = '[Recipe] Get Recipes Error',
  CreateRecipeAttempt = '[Recipe] Create Recipe Attempt',
  CreateRecipeImageAttempt = '[Recipe] Create Recipe Image Attempt',
  CreateRecipeSuccess = '[Recipe] Create Recipe Success',
  UpsertRecipeImageSuccess = '[Recipe] Upsert Recipe Image Success',
  CreateRecipeError = '[Recipe] Create Recipe Error',
  PatchRecipeAttempt = '[Recipe] Patch Recipe Attempt',
  PatchRecipeSuccess = '[Recipe] Patch Recipe Success',
  PatchRecipeError = '[Recipe] Patch Recipe Error',
  DeleteRecipeAttempt = '[Recipe] Delete Recipe Attempt',
  DeleteRecipeSuccess = '[Recipe] Delete Recipe Success',
  DeleteRecipeError = '[Recipe] Delete Recipe Error Attempt',
  GetRecipeIngredientsAttempt = '[Recipe] Get Recipe Ingredients',
  GetRecipeIngredientsSuccess = '[Recipe] Get Recipe Ingredients Success',
  GenericRecipeStoreUpdate = '[Recipe] Generic Recipe DataUpdate',
  TryRecalculateRecipePricing = '[Recipe] Try Recalculate Recipie Pricing',
  RecalculateRecipePricingCompleted = '[Recipe] Try Recalculate Recipie Pricing completed',
  RecalculateRecipePricing = '[Recipe] Recalculate Recipie Pricing',
  RefreshActiveRecipeIngredientItemData = '[Recipe] Refresh Active Recipe Ingredient Item Data',
  GetMostRecentWorksheetAttempt = '[Recipe] Get Most Recent Worksheet Attempt',
  GetMostRecentWorksheetSuccess = '[Recipe] Get Most Recent Worksheet Success',
  GetMostRecentWorksheetError = '[Recipe] Get Most Recent Worksheet Error',
  PricingCalculationSuccess = '[Recipe] Pricing Calculation Success',
  RefreshRecipePricingSuccess = '[Recipe] Refresh Recipe Pricing Success',
  CloneRecipeAttempt = '[Recipe] Clone Recipe Attempt',
  PrintRecipeBegin = '[Recipe] Print Recipe Begin',
  ShowPrintRecipe = '[Recipe] Show Print Recipe',
  GenericHttpError = 'Http Error',
  DownloadRecipeSummaryReport = '[Reports] Download Recipe Summary Report Attempt',
  DownloadRecipeSummarySuccess = '[Reports] Download Recipe Summary Report Success',
  DownloadRecipeSummaryError = '[Reports] Download Recipe Summary Report Error',
}

export class RefreshActiveRecipeIngredientItemData implements Action {
  readonly type = ActionTypes.RefreshActiveRecipeIngredientItemData;
  constructor(public payload: {
    ingredients: { itemId: string; itemType: string }[];
  }) {}
}
export class GetRecipesAttempt implements Action {
  readonly type = ActionTypes.GetRecipesAttempt;
}
export class SetActiveRecipeId implements Action {
  readonly type = ActionTypes.SetActiveRecipeId;
  constructor(public nextId: string) {}
}

export class RecipeLoading implements Action {
  readonly type = ActionTypes.RecipeLoading;
  constructor(public recipeId: string) {}
}

export class GetRecipesSuccess implements Action {
  readonly type = ActionTypes.GetRecipesSuccess;
  constructor(public recipes: Recipe[]) {}
}
export class DownloadRecipeSummarySuccess implements Action {
  readonly type = ActionTypes.DownloadRecipeSummarySuccess;
}
export class DownloadRecipeSummaryError implements Action {
  readonly type = ActionTypes.DownloadRecipeSummaryError;
  constructor(public payload: string) {}
}
export class DownloadRecipeSummaryReport implements Action {
  readonly type = ActionTypes.DownloadRecipeSummaryReport;
  constructor(public payload: {
    locale?: string,
    customerPk : CustomerPK,
    customerName: string
  }) {}
}

export class GenericError implements Action {
  readonly type = ActionTypes.GetRecipesError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetRecipesError implements Action {
  readonly type = ActionTypes.GetRecipesError;
  constructor(public error: HttpErrorResponse) {}
}

export class CreateRecipeAttempt implements Action {
  readonly type = ActionTypes.CreateRecipeAttempt;
  constructor(
    public payload: {
      recipe: Recipe;
      isDuplicate: boolean;
      analyticsActionName: string;
    }
  ) {}
}

export class CreateRecipeSuccess implements Action {
  readonly type = ActionTypes.CreateRecipeSuccess;
  constructor(
    public payload: { recipe: Recipe; analyticsActionName: string }
  ) {}
}

export class CreateRecipeImageAttempt implements Action {
  readonly type = ActionTypes.CreateRecipeImageAttempt;
  constructor(
    public payload: { recipeId: string; content: string; analyticsActionName: 'attempting to add image to recipe'; }
  ) { }
}

export class UpsertRecipeImageSuccess implements Action {
  readonly type = ActionTypes.UpsertRecipeImageSuccess;
  constructor(
    public payload: { analyticsActionName: string; }
  ) { }
}

export class CreateRecipeError implements Action {
  readonly type = ActionTypes.CreateRecipeError;
  constructor(public error: HttpErrorResponse) {}
}

export class PatchRecipeAttempt implements Action {
  readonly type = ActionTypes.PatchRecipeAttempt;
  constructor(
    public payload: { recipe: Recipe; analyticsActionName: string }
  ) {}
}

export class PatchRecipeSuccess implements Action {
  readonly type = ActionTypes.PatchRecipeSuccess;
  constructor(
    public payload: { recipe: Recipe; analyticsActionName: string }
  ) {}
}

export class PatchRecipeError implements Action {
  readonly type = ActionTypes.PatchRecipeError;
  constructor(public error: HttpErrorResponse) {}
}

export class DeleteRecipeAttempt implements Action {
  readonly type = ActionTypes.DeleteRecipeAttempt;
  constructor(public recipe: Recipe) {}
}

export class DeleteRecipeSuccess implements Action {
  readonly type = ActionTypes.DeleteRecipeSuccess;
  constructor(public recipe: Recipe) {}
}

export class DeleteRecipeError implements Action {
  readonly type = ActionTypes.DeleteRecipeError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetRecipeIngredientsAttempt implements Action {
  readonly type = ActionTypes.GetRecipeIngredientsAttempt;
  constructor(public payload: { recipeId: string }) {}
}

export class GetRecipeIngredientsSuccess implements Action {
  readonly type = ActionTypes.GetRecipeIngredientsSuccess;
  constructor(public payload: { ingredients: RecipeIngredient[] }) {}
}

export class GenericRecipeStoreUpdate implements Action {
  readonly type = ActionTypes.GenericRecipeStoreUpdate;
  constructor(public payload: { valuePath: string; value: any }) {}
}

export class GenericHttpError implements Action {
  readonly type = ActionTypes.GenericHttpError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetMostRecentWorksheetAttempt implements Action {
  readonly type = ActionTypes.GetMostRecentWorksheetAttempt;
  constructor() {}
}
export class GetMostRecentWorksheetSuccess implements Action {
  readonly type = ActionTypes.GetMostRecentWorksheetSuccess;
  constructor(public worksheet: Worksheet) {}
}
export class GetMostRecentWorksheetError implements Action {
  readonly type = ActionTypes.GetMostRecentWorksheetError;
  constructor(public error: HttpErrorResponse) {}
}

export class PricingCalculationSuccess implements Action {
  readonly type = ActionTypes.PricingCalculationSuccess;
  constructor(public pricing: RecipePrice) {}
}
export class RefreshRecipePricingSuccess implements Action {
  readonly type = ActionTypes.RefreshRecipePricingSuccess;
  constructor(public pricing: RecipePrice) {}
}
export class RecalculateRecipePricing implements Action {
  readonly type = ActionTypes.RecalculateRecipePricing;
  constructor(public recipeId: string) {}
}
export class TryRecalculateRecipePricing implements Action {
  readonly type = ActionTypes.TryRecalculateRecipePricing;
  constructor(public recipeId: string) { }
}
export class TryRecalculateRecipePricingCompleted implements Action {
  readonly type = ActionTypes.RecalculateRecipePricingCompleted;
  constructor(public recipeId: string) { }
}
export class CloneRecipeAttempt implements Action {
  readonly type = ActionTypes.CloneRecipeAttempt;
  constructor(public sourceRecipeId: string, public destinationRecipeId: string) {}
}

export class PrintRecipeBegin implements Action {
  readonly type = ActionTypes.PrintRecipeBegin;
  constructor(public recipeId: string) {}
}

export class ShowPrintRecipe implements Action {
  readonly type = ActionTypes.ShowPrintRecipe;
  constructor(public recipeId: string) {}
}

export type ActionUnion =
  | GetRecipesAttempt
  | GetRecipesSuccess
  | GetRecipesError
  | CreateRecipeAttempt
  | CreateRecipeSuccess
  | CreateRecipeError
  | PatchRecipeAttempt
  | PatchRecipeSuccess
  | PatchRecipeError
  | DeleteRecipeAttempt
  | DeleteRecipeSuccess
  | DeleteRecipeError
  | GetRecipeIngredientsAttempt
  | GetRecipeIngredientsSuccess
  | GenericHttpError
  | SetActiveRecipeId
  | RecipeLoading
  | GenericRecipeStoreUpdate
  | RefreshActiveRecipeIngredientItemData
  | GetMostRecentWorksheetAttempt
  | GetMostRecentWorksheetSuccess
  | GetMostRecentWorksheetError
  | PricingCalculationSuccess
  | RefreshRecipePricingSuccess
  | RecalculateRecipePricing
  | CloneRecipeAttempt
  | PrintRecipeBegin
  | ShowPrintRecipe
  | TryRecalculateRecipePricing
  | TryRecalculateRecipePricingCompleted
  | UpsertRecipeImageSuccess
  | CreateRecipeImageAttempt
 | DownloadRecipeSummaryReport 
 | DownloadRecipeSummarySuccess 
  | DownloadRecipeSummaryError ;
