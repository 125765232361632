import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { IAppContext, WorksheetItemConfigDTO, WorksheetLastModified, WorksheetUpdate } from '@gfs/shared-models';
import { createTimeBoxedOperation } from '@gfs/shared-services/extensions/rxjs';
import { BatchProductCountRequest, BatchProductCountResponse, CloneWorksheetItemResponse, DeleteWorksheetItemResponse, WorksheetDTO } from '@gfs/v2/shared-models';
import { Observable } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class WorksheetHttpService {

  updateWorksheetItemUnitPermission = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet.updateWorksheetItemUnit;
  currentEntitlement$ = this.appContext.customerPK
    .pipe(
      first(v => !!v),
      map(
        v => {
          delete v.hash;
          return v;
        }
      )
    );
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(InjectionTokens.IAPP_CONTEXT) private readonly appContext: IAppContext,
    @Inject(InjectionTokens.API_BASE_URL) API_BASE_URL: string
  ) {
    this.baseUrl = API_BASE_URL + '/api';
  }


  createWorksheetItemGetRequest(
    worksheetId: string,
    worksheetItemId: string,
  ) {
    return this.currentEntitlement$
      .pipe(
        concatMap(
          pk => {
            delete pk.hash;
            return this.http.get<WorksheetDTO>(
              `${this.baseUrl}/v2/worksheet/${worksheetId}/Item/${worksheetItemId}`,
              {
                headers: new HttpHeaders({
                  'customerPK': JSON.stringify(pk)
                })
              });
          }
        ),
      );
  }

  createWorksheetGetRequest(worksheetId, cacheBust = false,isRefreshPrice= false) {
    return this.currentEntitlement$
      .pipe(
        concatMap((pk) =>
          this.http.get<WorksheetDTO>(
            cacheBust ? `${this.baseUrl}/v2/worksheet/${worksheetId}?${+new Date()}` : `${this.baseUrl}/v2/worksheet/${worksheetId}`,
            {
              headers:
                new HttpHeaders({
                  'customerPK': JSON.stringify(pk),
                   'isRefreshPrice' : JSON.stringify(isRefreshPrice)
                })
            }
          )
        )
      );
  }

  createGroupDeleteWorksheetItemRequest(
    worksheetId: string,
    worksheetItemIds: Array<any>,
    storageAreaIds: string[]
  ) {
    worksheetItemIds = worksheetItemIds.map((items)=>{
      return items.item.worksheetItemId
    })
    return this.currentEntitlement$
      .pipe(
        concatMap((pk) =>
          this.http.post<any>(
            `${this.baseUrl}/v2/worksheet/${worksheetId}/bulk/delete`,
            {
              worksheetItemIds,
              storageAreaIds
            },
            {
              headers: new HttpHeaders({
                'customerPK': JSON.stringify(pk)
              })
            }
          )
        )
      );
  }

  createCloneWorksheetItemRequest(
    worksheetId: string,
    worksheetItemId: string
  ) {
    return this.currentEntitlement$
      .pipe(
        concatMap((pk) =>
          this.http.post<CloneWorksheetItemResponse>(
            `${this.baseUrl}/v2/worksheet/${worksheetId}/${worksheetItemId}/clone`,
            {},
            {
              headers: new HttpHeaders({
                'customerPK': JSON.stringify(pk)
              })
            }
          )
        )
      );
  }

  createDeleteWorksheetItemRequest(
    worksheetId: string,
    worksheetItemId: string
  ) {
    return this.currentEntitlement$
      .pipe(
        concatMap((pk) =>
          this.http.delete<DeleteWorksheetItemResponse>(
            `${this.baseUrl}/v2/worksheet/${worksheetId}/${worksheetItemId}`,
            {
              headers: new HttpHeaders({
                'customerPK': JSON.stringify(pk)
              })
            }
          )
        )
      );
  }

  createMoveWorksheetRequest(
    worksheetId: string,
    worksheetItemId: string,
    storageAreaId: string
  ) {
    return this.currentEntitlement$
      .pipe(
        concatMap((pk) =>
          this.http.patch<WorksheetDTO>(
            `${this.baseUrl}/v2/worksheet/${worksheetId}/${worksheetItemId}/move-to-storage-area/${storageAreaId}`,
            {},
            {
              headers:
                new HttpHeaders({
                  'customerPK': JSON.stringify(pk)
                })
            }
          )
        )
      );
  }

  createGroupMoveWorksheetRequest(
    worksheetId: string,
    worksheetItemIds: Array<any>,
    storageAreaIds: string[],
    targetStorageAreaId:string
  ) {
    worksheetItemIds = worksheetItemIds.map((items)=>{
      return items.item.worksheetItemId
    })
    return this.currentEntitlement$
      .pipe(
        concatMap((pk) =>
          this.http.post<WorksheetDTO>(
            `${this.baseUrl}/v2/worksheet/${worksheetId}/bulk/move-to-storage-area/${targetStorageAreaId}`,
            {
              worksheetItemIds,
              storageAreaIds
            },
            {
              headers:
                new HttpHeaders({
                  'customerPK': JSON.stringify(pk)
                })
            }
          )
        )
      );
  }

  createWorksheetItemSequencePatchRequest(
    worksheetId: string,
    storageAreaId: string,
    sequence: string[]
  ) {
    return this.currentEntitlement$
      .pipe(
        first(),
        concatMap((pk) =>
          this.http.patch<WorksheetDTO>(
            `${this.baseUrl}/v1/worksheets/${worksheetId}/storage-areas/${storageAreaId}`,
            {
              worksheetItemOrder: sequence
            },
            {
              headers:
                new HttpHeaders({
                  'customerPK': JSON.stringify(pk)
                })
            }
          )
        )
      );
  }

  createDeleteStorageAreaRequest(
    worksheetId: string,
    storageAreaId: string
  ): Observable<any> {
    return this.currentEntitlement$
      .pipe(
        first(),
        concatMap((pk) => this.http.delete<WorksheetLastModified>(
          `${this.baseUrl}/v2/worksheet/${worksheetId}/storage-areas/${storageAreaId}`,
          {
            headers:
              new HttpHeaders({
                'customerPK': JSON.stringify(pk)
              })
          }
        ))
      );
  }

  patchStorageAreaOrder(
    worksheetId: string,
    storageAreaOrder: string[]
  ): Observable<WorksheetLastModified> {

    const patch: WorksheetUpdate = {
      storageAreaOrder
    };

    return createTimeBoxedOperation(
      this.http
        .patch<WorksheetLastModified>(
          `${this.baseUrl}/v1/worksheets/${worksheetId}`,
          patch
        ),
      'Connection problems',
      10000
    );
  }

  createUpdateCountRequest(
    countBatch: BatchProductCountRequest,
  ) {
    return this.currentEntitlement$
      .pipe(
        first(),
        concatMap((pk) =>
          this.http.patch<BatchProductCountResponse>(
            `${this.baseUrl}/v2/worksheet/${countBatch.worksheetId}/count`,
            countBatch,
            {
              headers: new HttpHeaders({
                'customerPK': JSON.stringify(pk)
              })
            }
          )
        )
      );

  }

  createRequestGetEditableWorksheetItemGetRequest(
    worksheetId: string,
    worksheetItemId: string,
  ): Observable<WorksheetItemConfigDTO> {
    return this.currentEntitlement$
      .pipe(
        first(),
        concatMap(
          pk => {
            return this.http.get<WorksheetItemConfigDTO>(
              `${this.baseUrl}/v2/worksheet/${worksheetId}/Item/${worksheetItemId}/edit`,
              {
                headers: new HttpHeaders({
                  'customerPK': JSON.stringify(pk)
                })
              });
          })
      );
  }

}
